

const MainMissionIta = () => {
    return(
      <div className="text-center back-image mb-7">
        <h3 className="text-success pt-4 mb-4">LA NOSTRA MISSION</h3>
        <div className="w-40 m-auto">
      <h2 className="font-25">Digitalizzare il mondo degli sport dilettantistici per fornire le stesse opportunità a tutti</h2>
      </div>
      </div>
    )
}

export default MainMissionIta