
import { Navbar } from "react-bootstrap"
import { Link } from "react-router-dom"
import { connect } from 'react-redux'
import { defineLanguageAction } from '../redux/actions'

const mapStateToProps = (state) => ({
  language: state.language.type,
})

const mapDispatchToProps = (dispatch) => ({
 
  defineLanguage: (language) => {
    dispatch(defineLanguageAction(language))
  },
})

const MyNavbar = ({language, defineLanguage}) => {
  return (
    <Navbar className="padding-t">
      <Navbar.Brand href="/" className="text-bold margin-l font-18" onClick={()=> defineLanguage("ita")}>MidField</Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse className="justify-content-end">
        <Navbar.Text>
          <div className="text-dark margin-r">
            <Link to="/en">
              <span className={language=== "en" ? "mr-2 pointer font-weight-bold": "mr-2 pointer"} onClick={()=> defineLanguage("en")}>EN</span>
            </Link>
            <Link to="/">
              <span className={language=== "ita" ? "pointer font-weight-bold": "pointer"} onClick={()=> defineLanguage("ita")}>ITA</span>
            </Link>
          </div>
        </Navbar.Text>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MyNavbar)