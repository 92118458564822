import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import MyNavbar from './components/MyNavbar';
import MainPageIta from './components/MainPageIta';
import MainPageEn from './components/MainPageEn';
import Privacy from './components/Privacy';

function App() {
  return (
  <BrowserRouter>
    <div className='back-color' >
      <MyNavbar />
      <Routes>
        <Route path='/defaultsite' element={<MainPageIta />} />
        <Route path='/midfield' element={<MainPageIta />} />
        <Route path='/' element={<MainPageIta />} />
        <Route path='/en' element={<MainPageEn />} />
        <Route path='/midfield/en' element={<MainPageEn />} />
        <Route path='/privacy&policy' element={<Privacy />} />
      </Routes>
    </div>
  </BrowserRouter>
  );
}


export default App;
