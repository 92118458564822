import MainMissionEn from "./MainMissionEn"
import MainSectionEn from "./MainSectionEn"

const MainPageEn = () => {
    return(
       <>
       <MainMissionEn/>
       <MainSectionEn/>
       </>
    )
}

export default MainPageEn