import { Image } from "react-bootstrap"

const Employ = ({ name, title, description, image }) => {
  return (
    <div className="text-center">
      <Image src={image} className="mb-4" style={{ width: "300px" }} roundedCircle />
      <h3 className="mb-3">{name}</h3>
      <p className="mb-3">{title}</p>
      <p className="mb-3">{description}</p>
    </div>
  )
}

export default Employ