import { DEFINE_LANGUAGE } from '../actions'
import { initialState } from '../store'

const languageReducer = (state = initialState.language, action) => {
    switch (action.type) {

        case DEFINE_LANGUAGE:
            return {

                ...state,

                type: action.payload,
            }

        default:
            return state
    }
}

export default languageReducer