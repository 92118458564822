import { useState } from "react";
import { Col, Row, Image, Button, Form, Modal } from "react-bootstrap"
import Employ from "./Employ"
import axios from "axios";
import { Link } from "react-router-dom";

const MainSectionIta = () => {

  const [show, setShow] = useState(false);
  const [app, setApp] = useState(undefined)

  const [eMail, setEMail] = useState(undefined)
  const [os, setOs] = useState(undefined)
  const [application, setApplication] = useState(undefined)
  const [nome, setNome] = useState(undefined)
  const [messaggio, setMessaggio] = useState(undefined)
  const [privacy, setPrivacy] = useState(false)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = () => {
    axios.post("https://sheet.best/api/sheets/57e0c675-8a41-4016-9f5f-ab9c1068a99a", {
      eMail, os, application
    })
  }

  const handleSubmitWork = () => {
    axios.post("https://sheet.best/api/sheets/aeaa94d3-7c60-44b4-a208-e0570ffcc680", {
      nome, eMail, messaggio
    })
  }

  return (
    <>
      <div className="padding-t ">
        <h3 className="text-danger text-center mb-5">I NOSTRI SERVIZI</h3>
        <Row className="mb-5">
          <Col lg={6}>
            <Image className="dBlock app-img" src="/HL.png" />
            <div className="dNone"><h5>HighLights App</h5>
              <p>Non perderti mai più un istante: HighLights App è la prima e unica app con la quale puoi creare video highlights in tempo reale utilizzando pochissima memoria e batteria.
                <br></br>
                <br></br>
                Nella nuova versione sarà possibile customizzare i watermarks per dare spazio agli sponsor sportivi.
                <br></br>
                <br></br>
                Presto disponibile per Android e iOs.</p>
              <Button variant="success" onClick={() => { handleShow(); setApp("hl") }}>Scopri di più</Button>
            </div>
          </Col>
          <Col lg={6}>
            <Image className="dNone app-img" src="/HL.png" />
            <div className="dBlock">
              <h5>HighLights App</h5>
              <p>Non perderti mai più un istante: HighLights App è la prima e unica app con la quale puoi creare video highlights in tempo reale utilizzando pochissima memoria e batteria.
                <br></br>
                <br></br>
                Nella nuova versione sarà possibile customizzare i watermarks per dare spazio agli sponsor sportivi.
                <br></br>
                <br></br>
                Presto disponibile per Android e iOs.</p>
              <Button variant="success" onClick={() => { handleShow(); setApp("hl") }}>Scopri di più</Button>
            </div>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col lg={6}>
            <Image className="app-img-ch" src="/CH.png" />
          </Col>
          <Col lg={6}>
            <h5>Choir App</h5>
            <p>Unisciti al coro virtuale più grande del mondo! Sostieni la tua squadra preferita e fai sentire la tua voce.
              <br></br>
              <br></br>
              Un progetto visionario sviluppato durante l'arresto forzato in lockdown, a breve a disponibile per tutti i club del mondo.
            </p>
            <Button variant="danger" onClick={() => { handleShow(); setApp("choir") }}>Pronto per la beta?</Button>
          </Col>
        </Row>
        {app === "hl" ? <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>HighLights App news</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Quando la versione di HighLights App è pronta, avvisatemi a questo indirizzo: </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="e-mail"
                  autoFocus
                  onChange={(e) => { setEMail(e.target.value); setApplication("HL") }}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Io utilizzo:</Form.Label>
                <div key={`default-checkbox`} className="mb-3">
                  <Form.Check
                    type="checkbox"
                    id={`default-checkbox`}
                    label={"Android"}
                    onClick={() => setOs("Android")}
                  />
                </div>
                <div key={`default-checkbox`} className="mb-3">
                  <Form.Check
                    type="checkbox"
                    id={`default-checkbox`}
                    label={"Ios"}
                    onClick={() => setOs("Ios")}
                  />
                </div>
                <div key={`default-checkbox`} className="mb-3">
                  <Form.Check
                    type="checkbox"
                    id={`default-checkbox`}
                    label={"entrambi"}
                    onClick={() => setOs("entrambi")}
                  />
                </div>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Annulla
            </Button>
            <Button variant="success" onClick={() => { handleSubmit(); handleClose() }}>
              Invio
            </Button>
          </Modal.Footer>
        </Modal> : <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Choir App news</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Quando la versione di Choir App è pronta, avvisatemi a questo indirizzo: </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="e-mail"
                  autoFocus
                  onChange={(e) => { setEMail(e.target.value); setApplication("Choir") }}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Io utilizzo:</Form.Label>
                <div key={`default-checkbox`} className="mb-3">
                  <Form.Check
                    type="checkbox"
                    id={`default-checkbox`}
                    label={"Android"}
                    onClick={() => setOs("Android")}
                  />
                </div>
                <div key={`default-checkbox`} className="mb-3">
                  <Form.Check
                    type="checkbox"
                    id={`default-checkbox`}
                    label={"Ios"}
                    onClick={() => setOs("Ios")}
                  />
                </div>
                <div key={`default-checkbox`} className="mb-3">
                  <Form.Check
                    type="checkbox"
                    id={`default-checkbox`}
                    label={"entrambi"}
                    onClick={() => setOs("entrambi")}
                  />
                </div>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Annulla
            </Button>
            <Button variant="success" onClick={() => { handleSubmit(); handleClose() }}>
              Invio
            </Button>
          </Modal.Footer>
        </Modal>}
        <h3 className="text-primary text-center pt-4 mb-5">CHI SIAMO</h3>
        <Row className="mb-5">
          <Col md={6} lg={3}>
            <Employ image={"/TARI_LR (1).png"} name={"Shyqeri Tari"} title={"CEO"} description={"Amo lo sport e in particolar modo il mondo del calcio. Mi assicuro che tutte le scelte effettuate in questa fase delicata siano coerenti con la visione a lungo termine di MidField"} />
          </Col>
          <Col md={6} lg={3}>
            <Employ image={"/GNESDA.png"} name={"Marco Gnesda"} title={"COO"} description={"Voglio gestire un processo complesso e di grande impatto in cui istanze tecniche, economiche e umane possano combinarsi in una soluzione progettuale unica"} />
          </Col>
          <Col md={6} lg={3}>
            <Employ image={"/FABRIS.png"} name={"Paolo Fabris"} title={"CTO"} description={"Le competenze tecniche ti possono portare lontano, la passione in MidField. Programmare è la mia arte e passione che condivido con voi"} />
          </Col>
          <Col md={6} lg={3}>
            <Employ image={"/OLIVEIRA.png"} name={"Alessandro Oliveira"} title={"ART DIRECTOR"} description={"Con MidField potrò portare la mia arte a tutti e condividere le mie creazioni con il mondo dello sport a livello globale"} />
          </Col>
        </Row>
        <h3 className="text-danger text-center pt-4 mb-5">LAVORA CON NOI</h3>
        <div className=" w-80 text-center m-auto">
          <Form.Group>
            <Form.Control size="lg" className="mb-4" type="text" placeholder="Nome" onChange={(e) => { setNome(e.target.value) }} />
            <Form.Control size="lg" type="text" className="mb-4" placeholder="E-mail" onChange={(e) => { setEMail(e.target.value) }} />
            <Form.Control as="textarea" rows={5} placeholder="Messaggio" onChange={(e) => { setMessaggio(e.target.value) }} />
          </Form.Group>
          <Form>
            {['checkbox'].map((type) => (
              <div key={`default-${type}`} className="mb-3 d-flex">
                <Form.Check
                  type={type}
                  id={`default-${type}`}
                  onClick={() => setPrivacy(!privacy)}
                /> Accetto le vostre  <a href="/privacy&policy" className="marl-1"> Privacy Policy</a>
              </div>
            ))}
          </Form>
          <div>
            <Button variant="success" className="mb-4" onClick={() => { if (privacy) { handleSubmitWork(); alert("I dati sono stati inviati correttamente") } else { alert("devi accettare le privacy e policy per inviare la tua richiesta") } }}>Invia</Button>
          </div>
         <a  href="https://www.buymeacoffee.com/highlights"> <div className="buy-me m-auto pointer"></div></a>
         <div className="mt-4 iubenda m-auto pointer"></div>
        </div>
      </div>
      <div className="mt-4 footer padding-t ">
        <div className="d-flex justify-content-between align-items-center pt-50">
          <div className="">
            <span className="d-block" style={{ color: "white" }}>© 2020 MidField S.rl. Startup Innovativa P.iva 01352840324 - Trieste (IT)</span>
            <a href="/privacy&policy">Privacy Policy</a>
          </div>
          <div className="">
            <a href="https://www.facebook.com/create.highlights/">
            <Image className="width-round p-1 mr-2 pointer" src="/FB-ICON-BLACK.png" roundedCircle />
            </a>
            <a href="https://www.instagram.com/highlights.app/">
            <Image className="width-round p-2 mr-2 pointer " src="/INSTAGRAM-ICON-BLACK.png" roundedCircle />
            </a>
            <a href="https://www.linkedin.com/company/midfieldproject/">
            <Image className="width-round p-1 pointer" src="/LINKEDIN-ICON-BLACK.png" roundedCircle />
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default MainSectionIta