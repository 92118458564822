
import MainMissionIta from "./MainMissionIta"
import MainSectionIta from "./MainSectionIta"

const MainPageIta = () => {
    return(
        <>
        <MainMissionIta/>
        <MainSectionIta/>
        </>
    )
}

export default MainPageIta