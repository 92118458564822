
import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import languageReducer from '../reducers/language'
import thunk from 'redux-thunk'


const composeFunction = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const initialState = {


  language: {
    type: "ita",
  },

}


const bigReducer = combineReducers({
  language: languageReducer,
});

const configureStore = createStore(
  bigReducer,

  initialState,

  composeFunction(applyMiddleware(thunk))
);

export default configureStore;
