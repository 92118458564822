import { useState } from "react";
import { Col, Row, Image, Button, Form, Modal } from "react-bootstrap"
import Employ from "./Employ"
import axios from "axios";



const MainSectionEn = () => {

  const [show, setShow] = useState(false);
  const [app, setApp] = useState(undefined)

  const [eMail, setEMail] = useState(undefined)
  const [os, setOs] = useState(undefined)
  const [application, setApplication] = useState(undefined)
  const [nome, setNome] = useState(undefined)
  const [messaggio, setMessaggio] = useState(undefined)
  const [privacy, setPrivacy] = useState(false)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = () => {
    axios.post("https://sheet.best/api/sheets/57e0c675-8a41-4016-9f5f-ab9c1068a99a", {
      eMail, os, application
    })
  }

  const handleSubmitWork = () => {
    axios.post("https://sheet.best/api/sheets/aeaa94d3-7c60-44b4-a208-e0570ffcc680", {
      nome, eMail, messaggio
    })
  }


  return (
    <>
      <div className="padding-t ">
        <h3 className="text-danger text-center mb-5">OUR SERVICES</h3>
        <Row className="mb-5">
          <Col md={6}>
            <Image className="dBlock app-img" src="/HL.png" />
            <div className="dNone"> <h5>HighLights App</h5>
              <p>Never miss another moment: HighLights App is the first and only app with which you can create video highlights in real time, saving memory and battery!
                <br></br>
                <br></br>
                In the new version it will be possible to customize watermarks to give visibility to sports sponsors.
                <br></br>
                <br></br>
                Soon available for Android and iOs.</p>
              <Button variant="success" onClick={() => { handleShow(); setApp("hl") }}>More info</Button>
            </div>
          </Col>
          <Col md={6}>
            <Image className="dNone app-img" src="/HL.png" />
            <div className="dBlock"> <h5>HighLights App</h5>
              <p>Never miss another moment: HighLights App is the first and only app with which you can create video highlights in real time, saving memory and battery!
                <br></br>
                <br></br>
                In the new version it will be possible to customize watermarks to give visibility to sports sponsors.
                <br></br>
                <br></br>
                Soon available for Android and iOs.</p>
              <Button variant="success" onClick={() => { handleShow(); setApp("hl") }}>More info</Button>
            </div>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col md={6}>
            <Image className="app-img-ch" src="/CH.png" />
          </Col>
          <Col md={6}>
            <h5>Choir App</h5>
            <p>Join the world's largest virtual choir! Support your favorite team and make your voice heard.
              <br></br>
              <br></br>
              A visionary project developed during lockdown, soon to be available for all clubs in the world.
            </p>
            <Button variant="danger" onClick={() => { handleShow(); setApp("choir") }}>Beta ready</Button>
          </Col>
        </Row>
        {app === "hl" ?
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>HighLights App news</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>When the new version of HighLights App is ready, notify me at this address: </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="e-mail"
                    autoFocus
                    onChange={(e) => { setEMail(e.target.value); setApplication("HL") }}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>I am using:</Form.Label>

                  <div key={`default-checkbox`} className="mb-3">
                    <Form.Check
                      type="checkbox"
                      id={`default-checkbox`}
                      label={"Android"}
                      onClick={() => setOs("Android")}
                    />
                  </div>
                  <div key={`default-checkbox`} className="mb-3">
                    <Form.Check
                      type="checkbox"
                      id={`default-checkbox`}
                      label={"Ios"}
                      onClick={() => setOs("Ios")}
                    />
                  </div>
                  <div key={`default-checkbox`} className="mb-3">
                    <Form.Check
                      type="checkbox"
                      id={`default-checkbox`}
                      label={"both"}
                      onClick={() => setOs("both")}
                    />
                  </div>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleClose}>
                Close
              </Button>
              <Button variant="success" onClick={() => { handleSubmit(); handleClose() }}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal> : <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Choir App news</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>When the new version of Choir App is ready, notify me at this address: </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="e-mail"
                    autoFocus
                    onChange={(e) => { setEMail(e.target.value); setApplication("Choir") }}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>I am using:</Form.Label>

                  <div key={`default-checkbox`} className="mb-3">
                    <Form.Check
                      type="checkbox"
                      id={`default-checkbox`}
                      label={"Android"}
                      onClick={() => setOs("Android")}
                    />
                  </div>
                  <div key={`default-checkbox`} className="mb-3">
                    <Form.Check
                      type="checkbox"
                      id={`default-checkbox`}
                      label={"Ios"}
                      onClick={() => setOs("Ios")}
                    />
                  </div>
                  <div key={`default-checkbox`} className="mb-3">
                    <Form.Check
                      type="checkbox"
                      id={`default-checkbox`}
                      label={"both"}
                      onClick={() => setOs("both")}
                    />
                  </div>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleClose}>
                Close
              </Button>
              <Button variant="success" onClick={() => { handleSubmit(); handleClose() }}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>}
        <h3 className="text-primary text-center pt-4 mb-5">WHO WE ARE</h3>
        <Row className="mb-5">
          <Col md={6} lg={3}>
            <Employ image={"/TARI_LR (1).png"} name={"Shyqeri Tari"} title={"CEO"} description={"I love sports and especially the world of football. I make sure that all choices made at this sensitive stage are consistent with MidField's long-term vision"} />
          </Col>
          <Col md={6} lg={3}>
            <Employ image={"/GNESDA.png"} name={"Marco Gnesda"} title={"COO"} description={"I want to manage a complex, high-impact process in which technical, economic and human instances can combine into a single design solution"} />
          </Col>
          <Col md={6} lg={3}>
            <Employ image={"/FABRIS.png"} name={"Paolo Fabris"} title={"CTO"} description={"Technical skills can take you far, passion in MidField. Programming is my art and passion which I share with you"} />
          </Col>
          <Col md={6} lg={3}>
            <Employ image={"/OLIVEIRA.png"} name={"Alessandro Oliveira"} title={"ART DIRECTOR"} description={"With MidField I will be able to bring my art to everyone and share my creations with the sports world globally"} />
          </Col>
        </Row>
        <h3 className="text-danger text-center pt-4 mb-5">WORK WITH US</h3>
        <div className="w-80 text-center m-auto">
          <Form.Group>
            <Form.Control size="lg" className="mb-4" type="text" placeholder="Name" onChange={(e) => { setNome(e.target.value) }} />
            <Form.Control size="lg" type="text" className="mb-4" placeholder="E-mail" onChange={(e) => { setEMail(e.target.value) }} />
            <Form.Control as="textarea" rows={5} placeholder="Your message" onChange={(e) => { setMessaggio(e.target.value) }} />
          </Form.Group>
          <Form>
            {['checkbox'].map((type) => (
              <div key={`default-${type}`} className="mb-3 d-flex">
                <Form.Check
                  type={type}
                  id={`default-${type}`}
                  onClick={() => setPrivacy(!privacy)}
                /> I agree with your <a href="/privacy&policy" className="marl-1"> Privacy Policy</a>
              </div>
            ))}
          </Form>
          <div>
            <Button variant="success" className="mb-4" onClick={() => { if (privacy) { handleSubmitWork(); alert("data submitted successfully") } else { alert("Need to accept privacy and policy to send your request") } }}>Submit</Button>
          </div>
          <a  href="https://www.buymeacoffee.com/highlights"> <div className="buy-me m-auto pointer"></div></a>
          <div className="mt-4 iubenda m-auto pointer"></div>
        </div>
      </div>
      <div className="mt-4 footer padding-t ">
        <div className="d-flex justify-content-between align-items-center pt-50">
          <div className="">
            <span className="d-block" style={{ color: "white" }}>© 2020 MidField S.rl. Startup Innovativa P.iva 01352840324 - Trieste (IT)</span>
            <a href="/privacy&policy">Privacy Policy</a>
          </div>
          <div className="">
          <a href="https://www.facebook.com/create.highlights/">
            <Image className="width-round p-1 mr-2 pointer" src="/FB-ICON-BLACK.png" roundedCircle />
            </a>
            <a href="https://www.instagram.com/highlights.app/">
            <Image className="width-round p-2 mr-2 pointer " src="/INSTAGRAM-ICON-BLACK.png" roundedCircle />
            </a>
            <a href="https://www.linkedin.com/company/midfieldproject/">
            <Image className="width-round p-1 pointer" src="/LINKEDIN-ICON-BLACK.png" roundedCircle />
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default MainSectionEn